import React, {useState} from 'react';
import {
    Box, Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography
} from '@material-ui/core';
import MyDialog from 'src/components/Dialog';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { setSelected} from 'src/utils/TableUtils';
import useStyles from './style'
import {useSnackbar} from "notistack";

const TableComponent = ({
                            records,
                            reload,
                            headers,
                            getCells,
                            service
                        }) => {
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleSelectAllRecords = event => {
        setSelectedRecords(
            event.target.checked ? records.map(record => record.id) : []
        );
    };
    
    const handleDelete = async () => {
        const result = await service.remove(selectedRecords);
        if (result.status === 'ERROR') {
            enqueueSnackbar('Impossibile cancellare i record selezionati', {
                variant: 'error'
            });
        } else {
            await reload();
            enqueueSnackbar('Record cancellati', {
                variant: 'success'
            });
        }
        setSelectedRecords([]);
    };
    
    
    const handleSelectOneRecord = (event, patientId) => {
        setSelected(selectedRecords, setSelectedRecords, patientId);
    };
    
    const enableBulkOperations = selectedRecords.length > 0;
    const selectedSomeRecords =
        selectedRecords.length > 0 && selectedRecords.length < records.length;
    const selectedAllRecords = selectedRecords.length === records.length;
    
    
    return (
        <>
            {enableBulkOperations && (
                <div className={classes.bulkOperations}>
                    <div className={classes.bulkActions}>
                        <Checkbox
                            checked={selectedAllRecords}
                            indeterminate={selectedSomeRecords}
                            onChange={handleSelectAllRecords}
                        />
                        <Button
                            variant='outlined'
                            className={classes.bulkAction}
                            onClick={handleClickOpen}
                        >
                            Elimina
                        </Button>
                    
                    </div>
                </div>
            )}
            {records.length ? (
                    <PerfectScrollbar>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding='checkbox'>
                                        <Checkbox
                                            checked={selectedAllRecords}
                                            indeterminate={selectedSomeRecords}
                                            onChange={handleSelectAllRecords}
                                        />
                                    </TableCell>
                                    {headers.map(header =>
                                        <TableCell>{header}</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {records.map(record => {
                                    const isRecordSelected = selectedRecords.includes(
                                        record.id
                                    );
                                    
                                    return (
                                        <TableRow
                                            hover
                                            key={record.id}
                                            selected={isRecordSelected}
                                        >
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    checked={isRecordSelected}
                                                    onChange={event =>
                                                        handleSelectOneRecord(
                                                            event,
                                                            record.id
                                                        )
                                                    }
                                                    value={isRecordSelected}
                                                />
                                            </TableCell>
                                            {getCells(record)}
                                        </TableRow>
                                    );
                                })}
                            
                            </TableBody>
                        </Table>
                    </PerfectScrollbar>
                ) :
                <Box mt={5}>
                    <Typography>
                        Record non disponibili
                    </Typography>
                </Box>
            }
            
            <MyDialog
                elements={selectedRecords}
                handleDelete={handleDelete}
                open={open}
                setOpen={setOpen}
            />
        </>
    );
};

export default TableComponent;
