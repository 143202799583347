import React, {useEffect, useState} from 'react';
import Page from '../../../components/Page';
import {Box, Card, CardContent, Container} from '@material-ui/core';
import Header from '../../../layouts/DashboardLayout/Header';
import useStyles from '../../../components/EditComponent/style';
import getPage from '../../../services/PageService';
import * as calendarService from 'src/services/CalendarService';
import Calendar from 'src/components/Calendar';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

const RequestCalendar = ({patient}) => {
    const classes = useStyles();
    const title = 'Calendario attività';
    const page = getPage('requests.calendar');
    const [requests, setRequests] = useState([]);
    const history = useHistory();
    
    const shapeData = (values) => {
        let arr = [];
        values.map(value => 
            arr.push({
            id: value.id,
            title: value?.request?.service?.name,
            date: moment(value.deadline).format('YYYY-MM-DD')
        }));
        setRequests(arr);
    };
    
    const loadData = async () => {
        const result = await calendarService.search();
        shapeData(result.data)
    }
    
    useEffect(() => {
        loadData()
    }, []);
    
    const onEventClick = e => {
        !patient && history.push('/app/assigned/requests/' + e.event._def.publicId)
    }
    
    const onDateClick = e => {
    
    }
    
    return (
        <Page className={classes.root} title={title}>
            <Container maxWidth={false}>
                <Header title={title} breadcrumbs={page.breadcrumbs}/>
            </Container>
            <Box mt={3}>
                <Container>
                    <Card>
                        <CardContent>
                            
                        <Calendar
                            events={requests}
                            classes={classes}
                            onEventClick={onEventClick}
                            onDateClick={onDateClick}
                        />

                        </CardContent>
                    </Card>
                
                </Container>
            </Box>
        </Page>
    );
};

export default RequestCalendar;
