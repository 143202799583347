import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useTheme } from '@material-ui/core/styles';
import './style.css';

const Calendar = (props) => {
    const theme = useTheme();
    
    return (
        
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            locale='it'
            eventClassNames={props.classes.events}
            eventColor={theme.palette.secondary.main}
            events={props.events}
            eventClick={props.onEventClick}
            dateClick={props.onDateClick}
            buttonText={
                {
                    today: 'Oggi',
                    month: 'Mese',
                    week: 'Settimana',
                    day: 'Giorno',
                    list: 'Lista'
                }
            }
        
        />
    
    
    );
};


export default Calendar;
