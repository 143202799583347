import React, {Suspense, Fragment, lazy} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
import CoordinatorGuard from 'src/guards/CoordinatorGuard';
import Restricted from "src/guards/Restricted";
import AdminGuard from 'src/guards/AdminGuard';
import Request from "../views/request/RequestListView";
import RequestCalendar from "../views/request/RequestCalendar";
import RequestList from "../views/service/Patient/RequestList";
import PatientLayout from "../layouts/PatientLayout";
import {paths, VIEWACCESS} from 'src/constants'
import OperatorGuard from "../guards/OperatorGuard";
import AssistantGuard from "src/guards/AssistantGuard";
import Guard from "src/guards/Guard";
import managementRoutes from "./managementRoutes";
import adminRoutes from "./adminRoutes";

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen/>}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;
                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                            <Guard access={route.access}>
                                <Layout>
                                    {route.routes ? (
                                        renderRoutes(route.routes)
                                    ) : (
                                        <Component {...props} />
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);


const index = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
    },
    {
        path: '/app/patient',
        guard: AuthGuard,
        layout: PatientLayout,
        routes: [
            {
                exact: true,
                path: '/app/patient/services',
                component: lazy(() => import('src/views/service/Patient'))
            },

            {
                exact: true,
                path: [
                    '/app/patient/requests/'
                ],
                component: () => <RequestList patient={true}/>
            },
            {
                exact: true,
                path: '/app/patient/account',
                component: lazy(() => import('src/views/account/AccountView'))
            },

        ]
    },

    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app',
                component: lazy(() => import('src/views/view'))
            },
            {
                path: '/app/operator/',
                guard: OperatorGuard,
                routes: [
                    {
                        exact: true,
                        path: `${paths.operatorDashboard}`,
                        component: lazy(() => import('src/views/operatorDashboard'))
                    },
                ]
            },
            {
                exact: true,
                path: [
                    '/app/admin/requests-stops'
                ],
                guard: CoordinatorGuard,
                component: lazy(() =>
                    import('src/views/request/StopListView')
                )
            },
            {
                exact: true,
                path: [
                    '/app/admin/requests-route'
                ],
                guard: CoordinatorGuard,
                component: lazy(() =>
                    import('src/views/request/RouteListView')
                )
            },
            {
                path: '/app/assistant/',
                guard: Guard,
                access: VIEWACCESS.fall.management,
                routes: [
                    {
                        exact: true,
                        path: `${paths.assistantDashboard}`,
                        component: lazy(() => import('src/views/assistantDashboard'))
                    },
                    {
                        exact: true,
                        path: `${paths.assistantDashboard}/:fallId`,
                        component: lazy(() => import('src/views/assistantDashboard/InterventionEditView'))
                    },
                ]
            },
            adminRoutes,

            {
                exact: true,
                path: '/app/account',
                component: lazy(() => import('src/views/account/AccountView'))
            },

            {
                exact: true,
                path: [
                    `${paths.requests}/calendar`,
                ],
                component: () => <RequestCalendar/>
            },
            {
                exact: true,
                path:  `${paths.requests}`,
                component: () => <Request/>
            },
            {
                exact: true,
                path:  [`${paths.assignedRequests}/create`,
                    `${paths.assignedRequests}/:requestId`,
                ],
                component: lazy(() => import('src/views/request/AssignedRequestEditView'))
            },
            {
                exact: true,
                path:  `${paths.requests}/:requestId`,
                component: lazy(() => import('src/views/request/RequestEditView'))
            },
            {
                exact: true,
                path:  `${paths.assignedRequests}`,
                component: () => <Request assigned={true}/>
            },
            managementRoutes,
            {
                component: () => <Redirect to='/404'/>
            }
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: HomeView
            },
            {
                exact: true,
                path: '/register/result',
                component: lazy(() => import('src/views/auth/RegistrationComplete'))
            },
            {
                exact: true,
                guard: GuestGuard,
                path: '/login',
                component: lazy(() => import('src/views/auth/LoginView'))
            },
            {
                exact: true,
                guard: GuestGuard,
                path: '/pincode',
                component: lazy(() => import('src/views/auth/PincodeView'))
            },
            {
                exact: true,
                path: '/register',
                component: lazy(() => import('src/views/auth/RegisterView'))
            },

            {
                exact: true,
                path: '/recover-password/:token',
                component: lazy(() => import('src/views/auth/RecoverPasswordView'))
            },
            {
                component: () => <Redirect to='/404'/>
            }
        ]
    }
];

export default index;
