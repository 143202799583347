import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as cityService from 'src/services/CityService';
import * as patientService from 'src/services/PatientService';
import * as optionService from 'src/services/OptionService';
import * as questionService from 'src/services/QuestionService';
import * as userService from 'src/services/UserService';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';


export const CityAutoComplete = props => {
    const [options, setOptions] = useState([]);
    
    const searchCountries = (event, str) => {
        if (str?.length > 2) {
            cityService.search(str)
                .then(result => {
                    setOptions(result.data);
                });
        }
    };
    
    const onChange = (event, value) => {
        event.stopPropagation();
        props.onChange(event, value)
    }

    return (
        <Autocomplete
            options={options}
            getOptionLabel={option => option?.name || ''}
            onInputChange={ searchCountries}
            onChange={onChange}
            defaultValue={props.defaultValue || ''}
            filterOptions={options => options}
            disabled={props.disabled}
            renderInput={params => (
                <TextField
                    helperText={props.helperText}
                    value={props.value}
                    required={props.required}
                    error={props.error}
                    {...params}
                    id={props.id || ''}
                    label={props.label || 'Città'}
                    variant='outlined'
                />
            )}
            renderOption={(option, { inputValue }) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);
                
                return (
                    <>
                        {getSpans(parts)}
                    </>
                );
            }}
        />
    );
};

export const UserAutoComplete = props => {
    const allRecords = typeof props.all == "undefined" ? false : props.all;

    const [options, setOptions] = useState([]);

    const searchUsers = str => {
        userService
            .search(str)
            .then(result => setOptions(result.data));
    };
    
    const searchDoctors = str => {
        userService
            .searchDoctors(str)
            .then(result => setOptions(result.data));
    };
    
    const searchOperators = str => {
        userService
            .searchOperators(str)
            .then(result => setOptions(result.data));
    };

    const searchSocialOperators = str => {
        userService
            .searchSocialOperators(str)
            .then(result => setOptions(result.data));
    };

    const searchSocialCoordinators = str => {
        userService
            .searchSocialCoordinators(str)
            .then(result => setOptions(result.data));
    };
    
    const searchPatients = (str, all=allRecords) => {
        patientService
            .search({ str:str, all:all })
            .then(result => setOptions(result.data));
    };

    const search = (e, newValue) => {
        if (newValue?.length ) {
            if (props.case === 'dottori') {
                searchDoctors(newValue);
            }
            if (props.case === 'pazienti') {
                searchPatients(newValue);
            }
            if (props.case === 'utenti') {
                searchUsers({str : newValue});
            }
            if (props.case ==='utentipazienti') {
                searchUsers({str: newValue});
                searchPatients(newValue);
            }
            if (props.case === 'operatori') {
                searchOperators(newValue);
            }
            if (props.case === 'operatoriSociali') {
                searchSocialOperators(newValue);
            }
            if (props.case === 'coordinatoriOperatoriSociali') {
                searchSocialOperators(newValue);
                searchSocialCoordinators(newValue);
            }
        }
    };
    
    const onChange = (event, value) => {
        event.stopPropagation();
        props.onChange(event, value)
    }
    
    return (
        <Autocomplete
            options={options}
            getOptionLabel={option => option?.name?.length > 0 || option?.surname?.length > 0 ? option?.name + ' ' + option?.surname : ''}
            onInputChange={search}
            onChange={onChange}
            defaultValue={props.defaultValue || ''}
            disabled={props.disabled}
            filterOptions={options => options}
            renderInput={params => (
                <TextField
                    helperText={props.helperText}
                    value={props.value}
                    error={props.error}
                    {...params}
                    required={props.required}
                    id={props.id || ''}
                    label={props.label || 'Utenti'}
                    variant='outlined'
                />
            )}
            renderOption={(option, { inputValue }) => {
                const matches = match(option.name + ' ' + option.surname, inputValue);
                const parts = parse(option.name + ' ' + option.surname, matches);
            
                return (
                    <>
                        {getSpans(parts)}
                    </>
                );
            }}
        />
    );
};

export const SuggestionAutoComplete = props => {
    const [options, setOptions] = useState([]);
    
    const search = e => {
        if (e?.target?.value) {
            if (props.case === 'options') {
                searchSuggestions(e.target.value);
            } else {
                searchQuestions(e.target.value);
            }
        }
    };
    
    const searchSuggestions = (str) => {
        if (str) {
            optionService
                .search({ str })
                .then(result => setOptions(result.data));
        }
    };
    
    const searchQuestions = (str) => {
        if (str) {
            questionService
                .search({ str })
                .then(result => setOptions(result.data));
        }
    };
    
    const onChange = (event, value) => {
        event.stopPropagation();
        props.onChange(event, value)
    }
    
    return (
        <Autocomplete
            options={options}
            getOptionLabel={option => option?.text || ''}
            onInputChange={search}
            disabled={props.disabled}
            onChange={onChange}
            filterOptions={options => options}
            renderInput={params => (
                <TextField
                    helperText={props.helperText}
                    value={props.value}
                    error={props.error}
                    id={props.id || ''}
                    {...params}
                    label={props.label}
                    variant='outlined'
                />
            )}
            renderOption={(option, { inputValue }) => {
                const matches = match(option.text, inputValue);
                const parts = parse(option.text, matches);
            
                return (
                    <>
                        {getSpans(parts)}
                    </>
                );
            }}
        />
    );
};

const getSpans = (parts) => {
    return (
        <div>
            {
                parts.map((part, index) => (
                    <span
                        key={index}
                        style={{
                            fontWeight: part.highlight ? 700 : 400
                        }}
                    >
                        {part.text}
                    </span>
                ))
            }
        </div>
    );
};
