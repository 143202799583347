import AdminGuard from "../guards/AdminGuard";
import {paths} from "../constants";
import {lazy} from "react";

export default {
    path: '/app/admin/',
    guard: AdminGuard,
    routes: [
        {
            exact: true,
            path: `${paths.user}`,
            component: lazy(() => import('src/views/user/UserListView'))
        },
        {
            exact: true,
            path: `/app/admin/questions/management`,
            component: lazy(() => import('src/views/questionManagement'))
        },
        {
            exact: true,
            path: paths.dashboard,
            component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
        },
        {
            exact: true,
            path: [
                `${paths.user}/create`,
                `${paths.user}/:userId`,
                `${paths.user}/:userId/:mode`
            ],
            component: lazy(() => import('src/views/user/UserEditView'))
        },
        {
            exact: true,
            path: `${paths.log}`,
            component: lazy(() => import('src/views/log/LogListView'))
        },
        {
            exact: true,
            path: ['/app/admin/manual-insert'],
            component: lazy(() => import('src/views/log/ManualInsertView'))
        },
        {
            exact: true,
            path: `${paths.riskScale}`,
            component: lazy(() => import('src/views/riskScale/RiskScaleListView'))
        },
        {
            exact: true,
            path: [
                `${paths.riskScale}/create`,
                `${paths.riskScale}/:riskScaleId`,
                `${paths.riskScale}/:riskScaleId/:mode`
            ],
            component: lazy(() => import('src/views/riskScale/RiskScaleEditView'))
        },
        {
            exact: true,
            path: `${paths.unit}`,
            component: lazy(() =>
                import('src/views/unit/UnitListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.unit}/create`,
                `${paths.unit}/:unitId`,
                `${paths.unit}/:unitId/:mode`
            ],
            component: lazy(() =>
                import('src/views/unit/UnitEditView')
            )
        },
        {
            exact: true,
            path: `${paths.question}`,
            component: lazy(() =>
                import('src/views/question/QuestionListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.question}/create`,
                `${paths.question}/:questionId/:mode`,
                `${paths.question}/:questionId`
            ],
            component: lazy(() =>
                import('src/views/question/QuestionEditView')
            )
        },
        {
            exact: true,
            path: `${paths.service}`,
            component: lazy(() =>
                import('src/views/service/ServiceListView')
            )
        },          
        {
            exact: true,
            path: [
                `${paths.service}/create`,
                `${paths.service}/:serviceId/:mode`,
                `${paths.service}/:serviceId`
            ],
            component: lazy(() =>
                import('src/views/service/ServiceEditView')
            )
        },
        {
            exact: true,
            path: paths.category,
            component: lazy(() =>
                import('src/views/category/CategoryListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.category}/create`,
                `${paths.category}/:categoryId/:mode`,
                `${paths.category}/:categoryId`,
            ],
            component: lazy(() =>
                import('src/views/category/CategoryEditView')
            )
        },
        {
            exact: true,
            path: paths.option,
            component: lazy(() =>
                import('src/views/option/OptionListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.option}/create`,
                `${paths.option}/:optionId/:mode`,
                `${paths.option}/:optionId`,
            ],
            component: lazy(() =>
                import('src/views/option/OptionEditView')
            )
        },
        {
            exact: true,
            path: `${paths.screening}`,
            component: lazy(() =>
                import('src/views/screening/ScreeningListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.screening}/create`,
                `${paths.screening}/:screeningId/:mode`,
                `${paths.screening}/:screeningId`,
            ],
            component: lazy(() =>
                import('src/views/screening/ScreeningEditView')
            )
        },
        {
            exact: true,
            path: `${paths.alert}`,
            component: lazy(() =>
                import('src/views/alert/AlertListView')
            )
        },
        {
            exact: true,
            path: '/app/admin/sent-alerts',
            component: lazy(() =>
                import('src/views/alert/AlertSentListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.alert}/create`,
                `${paths.alert}/:alertId/:mode`,
                `${paths.alert}/:alertId`
            ],
            component: lazy(() =>
                import('src/views/alert/AlertEditView')
            )
        },
        {
            exact: true,
            path: [
                '/app/admin/screenings-calendar'
            ],
            component: lazy(() =>
                import('src/views/screening/ScreeningCalendar')
            )
        },       
        {
            exact: true,
            path: `${paths.spec}`,
            component: lazy(() =>
                import('src/views/spec/SpecListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.spec}/create`,
                `${paths.spec}/:specId/:mode`,
                `${paths.spec}/:specId`
            ],
            component: lazy(() =>
                import('src/views/spec/SpecEditView')
            )
        },
        {
            exact: true,
            path: `${paths.medicalData}`,
            component: lazy(() =>
                import('src/views/medicalData/MedicalDataListView')
            )
        },
        {
            exact: true,
            path: [
                `${paths.medicalData}/create`,
                `${paths.medicalData}/:medicalDataId/:mode`,
                `${paths.medicalData}/:medicalDataId`
            ],
            component: lazy(() =>
                import('src/views/medicalData/MedicalDataEditView')
            )
        },

    ]
}
