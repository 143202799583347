import {doGet, doPost} from './FetchWrapper';
import {getKeys} from "../utils/APIUtil";

export const search = (args) => {
    return doGet(`/api/?event=event.search${getKeys(args)}`)
};

export const searchAll = (args) => {
    return doGet(`/api/?event=event.searchAll${getKeys(args)}`)
};

export const get = (id) => {
    return doGet(`/api/?event=event.get&id=${id}`)
};

export const update = (body) => {
    return doPost(`/api/?event=event.update`, body)
};

export const remove = (body) => {
    return doPost(`/api/?event=event.delete`, body)
};

export const listByPatient = () => {
    return doGet(`/api/?event=event.listByPatient`)
};

export const cancel = (body) => {
    return doPost(`/api/?event=event.cancel`, body)
};
